export const INITIAL_STATE_TOPICS = [
    {
        title: "HABITAÇÃO",
        objectives: [
            {
                title: "Conecta Comunidade",
                url: "https://drive.google.com/file/d/18Gq2kM9gZHP6wHSxS4AGtlSZKcCdBmlm/view?usp=sharing",
            },
        ],
        open: false,
    },
    {
        title: "MOBILIDADE URBANA",
        objectives: [
            { title: "Mobilidade Corporativa", url: "https://drive.google.com/file/d/16kKEyUsQdeZxPj6sc7uQsh0rSycx26Zp/view?usp=sharing" },
            { title: "Dados em movimento", url: "https://drive.google.com/file/d/1AATA2SDsDQvo-bevwAXdX8uvecQmdDmp/view?usp=sharing" },
        ],
        open: false,
    },
    {
        title: "SEGURANÇA ALIMENTAR",
        objectives: [
            {
                title: "Super Agricultores",
                url: "https://drive.google.com/file/d/12CVxfKQJ8UTED-pZwK5349aKAT93hMwt/view?usp=sharing",
            },
            {
                title: "Mercado+B",
                url: "https://drive.google.com/file/d/1BZ-AF80INkcFzPrV8XQ5mGYrVY7AFldQ/view?usp=sharing",
            },
        ],
        open: false,
    },
    {
        title: "TRABALHO E RENDA",
        objectives: [{ title: "Observatório do futuro do trabalho", url: "https://drive.google.com/file/d/1oHWxQ33yVIR2BN2FAZss0bETDfzRxWLY/view?usp=sharing" }],
        open: false,
    },
    {
        title: "ECONOMIA CIRCULAR",
        objectives: [
            {
                title: "ReShopping",
                url: "https://drive.google.com/file/d/1kYnCPbVOUMVMIaBLpjA0ygvDPwSlOr1W/view?usp=sharing",
            },
        ],
        open: false,
    },
];
