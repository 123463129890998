import styles from "./style.module.css";

const SectionObjective = () => {
    return (
        <section id="main" className={styles.section}>
            <div className={styles.container}>
                <div className={styles.objectiveContainer}>
                    <div className={styles.imageContainer}>
                        <img width={501} height={397} alt="Imagem de uma rodovia com uma cidade de fundo." src="objective.png" />
                    </div>
                    <div className={styles.descriptionContainer}>
                        <div className={styles.spbContainer}>
                            <span className={styles.spb}>SP+B</span>
                        </div>
                        <h3 className={styles.objectiveTitle}>
                            O <span>SP+B</span> tem como objetivo a <span>cocriação de futuros desejáveis para a cidade</span> de São Paulo:
                        </h3>
                        <p className={styles.objectiveDescription}>
                            Juntos, estamos redefinindo o conceito de cidades inteligentes, indo além do senso comum para desenvolver, de forma colaborativa, uma visão sistêmica de
                            impacto positivo. Por meio da colaboração extrema e da conexão entre stakeholders,{" "}
                            <strong>construímos soluções inovadoras para um futuro mais sustentável e inclusivo.</strong>
                        </p>
                    </div>
                </div>
                <div className={styles.videoContainer}>
                    <iframe
                        width="560"
                        height="315"
                        src="https://www.youtube.com/embed/gXpxtG99ZLI?si=NAAIigvMaAbCMtey"
                        title="YouTube video player"
                        allowFullScreen
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    ></iframe>
                </div>
            </div>
        </section>
    );
};

export default SectionObjective;
